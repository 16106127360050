import {Axios} from '@asocial/fe-utils'
import {createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {useSelector} from 'react-redux'
import {CursorType, ErrorResponsesType} from 'shared/types'
import {AttachmentType, ThreadType} from '../types'

export enum TabThreadsKeys {
  active = 'active',
  draft = 'draft',
  deleted = 'deleted'
}

type InitialThreadsModelProps = {
  list: ThreadType[]
  tab: TabThreadsKeys
  search: string
  cursor: CursorType
  openThread?: ThreadType | null
  counters: Record<TabThreadsKeys, number>
  unreadCount: number
}

export const initialThreadsModel: InitialThreadsModelProps = {
  list: [],
  tab: TabThreadsKeys.active,
  search: '',
  cursor: '',
  openThread: undefined,
  counters: {
    active: 0,
    deleted: 0,
    draft: 0
  },
  unreadCount: 0
}

export const threadsModel = createSlice({
  name: 'threads',
  initialState: initialThreadsModel,
  reducers: {
    addThreadsList: (state, {payload}: PayloadAction<{cursor: string; data: ThreadType[]}>) => {
      if (state.cursor === payload.cursor) return

      state.cursor = payload.cursor
      state.list.push(...payload.data)
    },
    orderThreadList: (state, {payload: thread}: PayloadAction<ThreadType>) => {
      if (state.tab === TabThreadsKeys.active && thread.dateDeleted) return
      if (state.tab === TabThreadsKeys.draft && (!thread.draft || thread.dateDeleted)) return
      if (state.tab === TabThreadsKeys.deleted && !thread.dateDeleted) return

      // Во время search поднимаем только текущие треды
      if (state.search && !state.list.find((item) => item.interlocutor.id === thread.interlocutor.id)) return

      state.list = state.list.filter((item) => item.interlocutor.id !== thread.interlocutor.id)

      // Если тред запинен, то закидываем его в начало
      if (thread.pinned) {
        state.list.unshift(thread)
      } else {
        // Иначе закидываем в начало незапининых тредов
        const index = state.list.findIndex((item) => !item.pinned)
        index < 0 ? state.list.push(thread) : state.list.splice(index, 0, thread)
      }
    },
    setOpenThread: (state, {payload}: PayloadAction<ThreadType | null | undefined>) => {
      state.openThread = payload
    },
    restoreOpenThread: (state) => {
      if (!state.openThread) return

      state.openThread.dateDeleted = null
    },
    upPurchasesLettersOpenThread: (state) => {
      if (!state.openThread) return

      state.openThread.purchases.letters++
      state.unreadCount--
    },
    upPurchasesPhotosOpenThread: (state) => {
      if (!state.openThread) return

      state.openThread.purchases.photos = state.openThread.purchases.photos + 1
    },
    pinUnpinThread: (state, {payload}: PayloadAction<boolean>) => {
      if (!state.openThread) return

      state.openThread.pinned = payload
    },
    setUnreadCount: (state, {payload}: PayloadAction<number>) => {
      state.unreadCount = payload
    },
    addUnreadCount: (state, {payload}: PayloadAction<number>) => {
      state.unreadCount += payload
    },
    setThreadsTab: (state, {payload}: PayloadAction<TabThreadsKeys>) => {
      state.tab = payload
    },
    setThreadsSearch: (state, {payload: search}: PayloadAction<string>) => {
      if (state.search === search) return

      state.list = []
      state.cursor = ''
      state.search = search
    },
    deleteThreads: (state, {payload}: PayloadAction<string[]>) => {
      state.list = state.list.filter((item) => !payload.includes(item.interlocutor.id))
    },
    setCounters: (state, {payload}: PayloadAction<Record<TabThreadsKeys, number>>) => {
      state.counters = payload
    },
    clearThreadsList: (state) => {
      state.list = []
      state.cursor = ''
    }
  }
})

export const {
  addThreadsList,
  setOpenThread,
  setUnreadCount,
  setThreadsTab,
  pinUnpinThread,
  clearThreadsList,
  setThreadsSearch,
  deleteThreads,
  setCounters,
  upPurchasesLettersOpenThread,
  upPurchasesPhotosOpenThread,
  addUnreadCount,
  orderThreadList,
  restoreOpenThread
} = threadsModel.actions

type GetThreadsResponse = AxiosResponse<{
  cursor: string
  data: ThreadType[]
}>

export const GetThreads = (dispatch: Dispatch) =>
  useQuery<GetThreadsResponse, ErrorResponsesType, GetThreadsResponse, [CursorType, TabThreadsKeys, string, string]>({
    queryKey: [
      useSelector((state: RootState) => state.threads.cursor),
      useSelector((state: RootState) => state.threads.tab),
      useSelector((state: RootState) => state.threads.search),
      'getThreads'
    ],
    queryFn: ({queryKey: [cursor, tab, search], signal}) =>
      Axios.get(`/mail/threads/${tab}`, {params: {cursor: cursor || null, search: search || null}, signal}).then(
        (response: GetThreadsResponse) => {
          dispatch(addThreadsList(response.data))

          return response
        }
      ),
    enabled: false,
    refetchOnWindowFocus: false
  })

type GetCountersResponse = AxiosResponse<Record<TabThreadsKeys, number>>

export const GetMailCounters = (dispatch: Dispatch) =>
  useQuery<GetCountersResponse, ErrorResponsesType>({
    queryKey: ['getMailCounters', dispatch],
    queryFn: ({signal}) =>
      Axios.get('/mail/counters', {signal}).then((response: GetCountersResponse) => {
        dispatch(setCounters(response.data))

        return response
      }),
    enabled: false,
    refetchOnWindowFocus: false
  })

export type GetThreadResponse = AxiosResponse<ThreadType | null>

export const CollectionsMailRequest = (
  idAttachment: string,
  idLetter: string,
  idUser: string
): Promise<AxiosResponse<AttachmentType>> =>
  Axios.post(`/mail/threads/${idUser}/letters/${idLetter}/attachments/${idAttachment}/collect`)

export const GetThread = (idUser: string, dispatch: Dispatch) =>
  useQuery<GetThreadResponse, ErrorResponsesType>({
    queryKey: ['getThreads', idUser],
    queryFn: ({signal}) =>
      Axios.get(`/mail/threads/${idUser}`, {signal}).then((response: GetThreadResponse) => {
        dispatch(setOpenThread(response.data))

        return response
      }),
    enabled: false,
    refetchOnWindowFocus: false
  })

export const PinUnpinThread = (idUser: string, pin: boolean, dispatch: Dispatch) =>
  useQuery<AxiosResponse<null>, ErrorResponsesType>({
    queryKey: ['pinUnpinThread', idUser, pin],
    queryFn: ({signal}) =>
      Axios.patch(`mail/threads/${idUser}/${pin ? 'pin' : 'unpin'}`, {signal}).then((response) => {
        dispatch(pinUnpinThread(pin))

        return response
      }),
    enabled: false,
    refetchOnWindowFocus: false
  })

export const DeleteThread = (ids: string[], dispatch: Dispatch) =>
  useQuery<AxiosResponse<null>, ErrorResponsesType>({
    queryKey: ['deleteThread', ids],
    queryFn: ({signal}) =>
      Axios.post(`mail/threads/delete`, {ids}, {signal}).then((response) => {
        dispatch(deleteThreads(ids))

        return response
      }),
    enabled: false,
    refetchOnWindowFocus: false
  })

export const RestoreThread = (ids: string[], dispatch: Dispatch) =>
  useQuery<AxiosResponse<null>, ErrorResponsesType>({
    queryKey: ['restoreThread', ids],
    queryFn: ({signal}) =>
      Axios.post(`mail/threads/restore`, {ids: ids}, {signal}).then((response) => {
        dispatch(deleteThreads(ids))

        return response
      }),
    enabled: false,
    refetchOnWindowFocus: false
  })

export const useThreadsList = () => useSelector((state: RootState) => state.threads.list)

export const useThreadsCursor = () => useSelector((state: RootState) => state.threads.cursor)

export const useThreadsUnreadCount = () => useSelector((state: RootState) => state.threads.unreadCount)

export const useThreadsActiveTab = () => useSelector((state: RootState) => state.threads.tab)

export const useOpenThread = () => useSelector((state: RootState) => state.threads.openThread)

export const useCounters = () => useSelector((state: RootState) => state.threads.counters)

export const useThreadsSearch = () => useSelector((state: RootState) => state.threads.search)
