import {useEffect, useState} from 'react'
import {currentMirror} from './config'

export type textType = {
  main: {
    siteName: string
    supportMail: string
    welcomeText: string
    mainAddress: string
    londonAddress: string
    estoniaAddress: string
    usAddress: string
    facebook: string
    instagram: string
    youtube: string
    twitter: string
    medium: string
    coin: string
    supportServicePhone: string
    certificateBudgetSavvy: string
    certificateTechUnwrapped: string
    certificatePI: string
    certificateDifferenceWise: string
    certificateRedGif: string
    certificateSupplyChain: string
    certificateGamesTech: string
  }
  lottie: {
    inbox: Object
  }
  people: {
    verifyCard: string
    verifyMini: string
    verifyNotification: string
  }
  seo: {
    registrationTitle: string
    registrationDescription: string
    authTitle: string
    blogTitle: string
    blogDescription: string
    blogHeader: string
  }
}

export const text: textType = {
  main: {
    siteName: '',
    supportMail: '',
    welcomeText: '',
    mainAddress: '',
    londonAddress: '',
    estoniaAddress: '',
    usAddress: '',
    facebook: '',
    instagram: '',
    youtube: '',
    twitter: '',
    medium: '',
    coin: '',
    supportServicePhone: '',
    certificateBudgetSavvy: '',
    certificateTechUnwrapped: '',
    certificatePI: '',
    certificateDifferenceWise: '',
    certificateRedGif: '',
    certificateSupplyChain: '',
    certificateGamesTech: ''
  },
  lottie: {
    inbox: {}
  },
  people: {
    verifyCard: '',
    verifyMini: '',
    verifyNotification: ''
  },
  seo: {
    registrationTitle: '',
    registrationDescription: '',
    authTitle: '',
    blogTitle: '',
    blogDescription: '',
    blogHeader: ''
  }
}

export function useTextLoad(): boolean {
  const [isLoad, setIsLoad] = useState(false)

  useEffect(() => {
    const loading = async () => {
      for (const key of Object.keys(text)) {
        text[key as keyof textType] = await import(`shared/mirrors/${currentMirror}/text/${key}`).then(
          (module) => module[key]
        )
      }

      setIsLoad(true)
    }

    loading().catch(console.error)
  }, [])

  return isLoad
}
