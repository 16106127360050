import React, {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  ReactElement,
  Ref,
  useMemo
} from 'react'
import {Tooltip} from '../tooltip'
import {Clear} from './icon/icon'
import styles from './styles.module.scss'

export interface InputContainerProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string
  label?: string
  containerStyles?: React.CSSProperties
  children?: React.ReactNode
  error?: string
  fullwidth?: boolean
  isRequiredLabel?: boolean
}

interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string
  label?: string
  ref: string
  containerStyles?: React.CSSProperties
  children?: React.ReactNode
  iconStart?: ReactElement
  error?: string
  fullwidth?: boolean
  className?: string
  isClerable?: boolean
  clerable?: Function
  multiline?: boolean
  withCounter?: boolean
  maxLength?: number
  inputContainerStyles?: React.CSSProperties
  inputContainerClassName?: string
  isRequiredLabel?: boolean
  withoutAutoComplete?: boolean
}

const Field: ForwardRefRenderFunction<HTMLInputElement, Omit<TextFieldProps, 'ref'>> = (
  {
    name,
    label,
    containerStyles,
    children,
    error,
    fullwidth = true,
    className,
    iconStart,
    isClerable,
    clerable,
    value,
    onChange,
    multiline,
    inputContainerStyles,
    inputContainerClassName,
    placeholder,
    required,
    withCounter,
    maxLength,
    isRequiredLabel,
    withoutAutoComplete,
    autoComplete,
    ...otherProps
  },
  ref
) => {
  const characterLeft = useMemo(() => {
    if (!maxLength) return
    if (!value) return maxLength

    return maxLength - Number(value.toString().length)
  }, [maxLength, value])

  return (
    <InputContainerTextField
      label={label}
      name={name}
      containerStyles={containerStyles}
      fullwidth={fullwidth}
      required={required}
      isRequiredLabel={isRequiredLabel}
      error={error}
    >
      <div
        style={{...inputContainerStyles, flexDirection: withCounter && maxLength ? 'column' : 'row'}}
        className={`${styles.inputContainer} ${!!error ? styles.inputError : ''} ${label ? styles.isLabel : ''} ${inputContainerClassName || ''}`}
      >
        {iconStart ?
          <div className={styles.iconStart}>{iconStart}</div>
        : null}
        {!multiline ?
          <input
            className={styles.formInput}
            value={value || ''}
            onChange={(e) => {
              if (!onChange) return
              if (maxLength && e.target.value.length > maxLength) return

              onChange(e)
            }}
            {...otherProps}
            placeholder={placeholder}
            name={name}
            id={name}
            autoComplete={withoutAutoComplete ? undefined : autoComplete || name}
            ref={ref}
            required={required}
          />
        : <>
            <textarea
              className={`${styles.formInput} ${styles.customScroll}`}
              value={value || ''}
              placeholder={placeholder}
              onChange={(e) => {
                if (!onChange) return

                const typedEvent = e as unknown as ChangeEvent<HTMLInputElement>
                if (maxLength && typedEvent.target.value.length > maxLength) return

                onChange(typedEvent)
              }}
              name={name}
              id={name}
              autoComplete={withoutAutoComplete ? undefined : autoComplete || name}
              ref={ref as Ref<HTMLTextAreaElement>}
              required={required}
            />
            <Tooltip title='Number of characters allowed' arrow={false}>
              <span className={styles.formInput__counter}>{characterLeft}</span>
            </Tooltip>
          </>
        }
        {isClerable && onChange && value ?
          <div
            className={styles.clerableIcon}
            onClick={() => {
              onChange({target: {value: ''}} as ChangeEvent<HTMLInputElement>)
            }}
          >
            <Clear />{' '}
          </div>
        : null}
        {clerable && value ?
          <div className={styles.clerableIcon} onClick={() => clerable()}>
            <Clear />
          </div>
        : null}
        {children}
      </div>
    </InputContainerTextField>
  )
}

export const InputContainerTextField: React.FC<InputContainerProps> = ({
  name,
  label,
  containerStyles,
  children,
  fullwidth = true,
  className,
  error,
  required,
  isRequiredLabel = true
}) => (
  <div
    className={`${styles.container} ${className || ''}`}
    style={{width: fullwidth ? '100%' : 'auto', ...containerStyles}}
  >
    {label ?
      <div className={styles.labelContainer}>
        <label className={styles.formLabel} htmlFor={name}>
          {label}
        </label>
        {isRequiredLabel && required ?
          <span className={styles.formLabel_required}>*</span>
        : null}
      </div>
    : null}
    {children}
    {error?.trim() ?
      <div className={styles.error}>{error}</div>
    : null}
  </div>
)

export const TextField = forwardRef(Field)
