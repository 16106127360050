import MuiAvatar, {AvatarProps as MuiAvatarProps} from '@mui/material/Avatar'
import {FC} from 'react'
import styles from './styles.module.scss'

type AvatarProps = {
  isOnline?: boolean
  onlineClass?: string
} & MuiAvatarProps

export const Avatar: FC<AvatarProps> = ({sx, isOnline, onlineClass, children, ...rest}) => (
  <div className={styles.avatar}>
    <MuiAvatar sx={{width: 44, height: 44, ...sx}} {...rest} />
    {isOnline ?
      <div className={`${styles.online} ${onlineClass}`} />
    : null}
    {children}
  </div>
)
