import {Axios} from '@asocial/fe-utils'
import {createSelector, createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {useSelector} from 'react-redux'
import {ImageType} from 'entities/messages'
import {retryInsufficientFunds} from 'shared/api/retry'
import {CursorType, ErrorResponsesType} from 'shared/types'
import {AttachmentType, AttachmentTypeEnum, LetterDraftType, LetterType} from '../types'
import {upPurchasesPhotosOpenThread} from './threads'

export type LettersModelType = {
  cursor: CursorType
  list: LetterType[]
  draft: LetterDraftType
  isBack: boolean
}

const initialStateLettersModel: LettersModelType = {
  cursor: '',
  list: [],
  draft: {
    dateCreated: '',
    dateUpdated: '',
    text: '',
    photos: [],
    videos: []
  },
  isBack: false
}

export const lettersModel = createSlice({
  name: 'lettersModel',
  initialState: initialStateLettersModel,
  reducers: {
    setIsBack(state, {payload}: PayloadAction<boolean>) {
      state.isBack = payload
    },
    addLettersListUp: (state, {payload}: PayloadAction<LetterType[]>) => {
      for (const letter of payload) {
        state.list.unshift(letter)
      }
    },
    addLetterListDown: (state, {payload: letter}: PayloadAction<LetterType>) => {
      // Duplicate check
      if (state.list.find((item) => item.id === letter.id)) return

      state.list.push(letter)
    },
    updateLetter: (state, {payload}: PayloadAction<LetterType>) => {
      state.list = state.list.map((item) => (item.id === payload.id ? payload : item))
    },
    updateLetterAttachment: (state, {payload}: PayloadAction<AttachmentType>) => {
      state.list = state.list.map((item) => ({
        ...item,
        attachments: item.attachments.map((attachment) => (attachment.id === payload.id ? payload : attachment))
      }))
    },
    lettersSetCursor: (state, {payload}: PayloadAction<CursorType>) => {
      state.cursor = payload
    },
    setLettersDraft: (state, {payload}: PayloadAction<LetterDraftType>) => {
      if (payload.text == null) payload.text = ''

      state.draft = payload
    },
    setDraftText: (state, {payload}: PayloadAction<string>) => {
      state.draft.text = payload
    },
    setDraftPhoto: (state, {payload}: PayloadAction<ImageType>) => {
      state.draft.photos.push({...payload, isLoading: true, isLocal: true})
    },
    saveDraftPhoto: (state, {payload}: PayloadAction<string>) => {
      state.draft.photos = state.draft.photos.map((item) => (item.key === payload ? {...item, isLoading: false} : item))
    },
    deleteDraftPhoto: (state, {payload: key}: PayloadAction<string>) => {
      state.draft.photos = state.draft.photos.filter((item) => item.key !== key)
    },
    setDraftVideo: (state, {payload}: PayloadAction<{cfId: string; previewUrl: string}>) => {
      state.draft.videos.push({
        ...payload,
        url: '',
        jpgUrl: '',
        iframeUrl: '',
        gifUrl: '',
        isLoading: true,
        isLocal: true
      })
    },
    saveDraftVideo: (state, {payload}: PayloadAction<string>) => {
      state.draft.videos = state.draft.videos.map((item) =>
        item.cfId === payload ? {...item, isLoading: false} : item
      )
    },
    deleteDraftVideo: (state, {payload: cfId}: PayloadAction<string>) => {
      state.draft.videos = state.draft.videos.filter((item) => item.cfId !== cfId)
    },
    updateAttachment: (state, {payload}: PayloadAction<LetterDraftType>) => {
      const loadingPhotos = state.draft.photos.filter(({isLoading}) => !isLoading)

      for (const photo of loadingPhotos) {
        const savedPhoto = payload.photos.find(({key}) => key === photo.key)

        savedPhoto &&
          (state.draft.photos = state.draft.photos.map((item) => (item.key === photo.key ? savedPhoto : item)))
      }

      const loadingVideos = state.draft.videos.filter(({isLoading}) => !isLoading)

      for (const video of loadingVideos) {
        const savedVideo = payload.videos.find(({cfId}) => cfId === video.cfId)

        savedVideo &&
          (state.draft.videos = state.draft.videos.map((item) => (item.cfId === video.cfId ? savedVideo : item)))
      }
    },
    purchaseAttachment: (state, {payload}: PayloadAction<{idLetter: string; idAttachment: string}>) => {
      const {idLetter, idAttachment} = payload

      const letter = state.list.find(({id}) => id === idLetter)
      if (!letter) return

      const attachment = letter.attachments.find(({id}) => id === idAttachment)
      if (!attachment) return

      attachment.content.purchased = true
    },
    clearLettersState: (state) => {
      state.cursor = ''
      state.list = []
    },
    clearDraftState: (state) => {
      state.draft = {
        dateCreated: '',
        dateUpdated: '',
        text: '',
        photos: [],
        videos: []
      }
    }
  }
})

export const {
  setIsBack,
  addLettersListUp,
  addLetterListDown,
  lettersSetCursor,
  setLettersDraft,
  setDraftText,
  setDraftPhoto,
  setDraftVideo,
  clearLettersState,
  clearDraftState,
  saveDraftPhoto,
  saveDraftVideo,
  updateAttachment,
  updateLetter,
  deleteDraftPhoto,
  deleteDraftVideo,
  purchaseAttachment,
  updateLetterAttachment
} = lettersModel.actions

type GetLettersResponse = {
  cursor: string
  data: LetterType[]
}

export const GetLetters = (idUser: string, cursor: CursorType, dispatch: Dispatch) =>
  useQuery<AxiosResponse<GetLettersResponse>, ErrorResponsesType>({
    queryKey: ['getLetters', idUser, cursor],
    queryFn: ({signal}) =>
      Axios.get(`/mail/threads/${idUser}/letters`, {params: {cursor: cursor || null}, signal}).then(
        ({data: response}) => {
          dispatch(addLettersListUp(response.data))
          dispatch(lettersSetCursor(response.cursor))

          return response.data
        }
      ),
    enabled: false,
    refetchOnWindowFocus: false
  })

export const GetDraft = (idUser: string, dispatch: Dispatch) =>
  useQuery<AxiosResponse<LetterDraftType>, ErrorResponsesType>({
    queryKey: ['getDraft', idUser],
    queryFn: ({signal}) =>
      Axios.get(`/mail/threads/${idUser}/draft`, {signal}).then((response: AxiosResponse<LetterDraftType>) => {
        response.data && dispatch(setLettersDraft(response.data))

        return response
      }),
    enabled: false,
    refetchOnWindowFocus: false
  })

export const SaveDraft = (idUser: string, dispatch: Dispatch) =>
  useQuery<
    AxiosResponse<LetterDraftType>,
    ErrorResponsesType,
    AxiosResponse<LetterDraftType>,
    [LetterDraftType, string, string]
  >({
    queryKey: [
      useSelector(
        createSelector(
          (state: RootState) => state.letters.draft,
          (draft) => ({
            ...draft,
            photos: draft.photos.filter((photo) => !photo.isLoading),
            videos: draft.videos.filter((video) => !video.isLoading)
          })
        )
      ),
      'saveDraft',
      idUser
    ],
    queryFn: ({signal, queryKey: [draft]}) =>
      Axios.post(`/mail/threads/${idUser}/draft`, draft, {signal}).then((response: AxiosResponse<LetterDraftType>) => {
        dispatch(updateAttachment(response.data))

        return response
      }),
    enabled: false,
    refetchOnWindowFocus: false
  })

export const SendLetter = (idUser: string, dispatch: Dispatch) =>
  useQuery<AxiosResponse<LetterType>, ErrorResponsesType>({
    queryKey: [useSelector((state: RootState) => state.letters.draft), 'sendLetter', idUser],
    queryFn: ({signal, queryKey: [draft]}) =>
      Axios.post(`/mail/threads/${idUser}/letters`, draft, {signal}).then((response: AxiosResponse<LetterType>) => {
        dispatch(clearDraftState())
        dispatch(addLetterListDown(response.data))

        return response
      }),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: retryInsufficientFunds
  })

export const PurchaseLetter = (idUser: string, idLetter: string, dispatch: Dispatch) =>
  useQuery<AxiosResponse<LetterType>, ErrorResponsesType>({
    queryKey: ['purchaseLetter', idUser, idLetter],
    queryFn: () =>
      Axios.post(`/mail/threads/${idUser}/letters/${idLetter}/purchase`, {}).then(
        (response: AxiosResponse<LetterType>) => {
          dispatch(updateLetter(response.data))

          return response
        }
      ),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: retryInsufficientFunds
  })

export const PurchaseAttachment = (
  idUser: string,
  idLetter: string,
  idAttachment: string,
  typeAttachment: AttachmentTypeEnum,
  dispatch: Dispatch,
  openMedia: () => void
) =>
  useQuery<AxiosResponse<null>, ErrorResponsesType>({
    queryKey: ['purchaseLetter', idUser, idLetter, idAttachment],
    queryFn: ({signal}) =>
      Axios.post(`/mail/threads/${idUser}/letters/${idLetter}/attachments/${idAttachment}/purchase`, {}, {signal}).then(
        (response) => {
          dispatch(purchaseAttachment({idLetter: idLetter, idAttachment: idAttachment}))
          openMedia()
          typeAttachment === AttachmentTypeEnum.PHOTO && dispatch(upPurchasesPhotosOpenThread())
          return response
        }
      ),
    enabled: false,
    refetchOnWindowFocus: false,
    retry: retryInsufficientFunds
  })

export const useLettersList = () => useSelector((state: RootState) => state.letters.list)

export const useLettersCursor = () => useSelector((state: RootState) => state.letters.cursor)

export const useLettersDraft = () => useSelector((state: RootState) => state.letters.draft)

export const useIsLettersToEachOther = (idUser: string) =>
  useSelector(
    createSelector(
      (state: RootState) => state.letters.list,
      (list) => !!list.find((item) => item.from.id === idUser) && !!list.find((item) => item.from.id !== idUser)
    )
  )

export const useIsBack = () => useSelector((state: RootState) => state.letters.isBack)
