import Modal from '@mui/material/Modal'
import {FC, ReactNode} from 'react'
import {names} from 'shared/lib'
import {img} from 'shared/mirrors/img'
import {text} from 'shared/mirrors/text'
import styles from './styles.module.scss'

type AuthModalProps = {
  children: ReactNode
  onSubmit?: Function
  className?: string
  wrapperClassName?: string
  defaultBackdrop?: boolean
  onClickBackdrop?: VoidFunction
}

export const AuthModal: FC<AuthModalProps> = (props) => {
  const {children, className, wrapperClassName, onSubmit, defaultBackdrop, onClickBackdrop, ...rest} = props

  return (
    <Modal
      open
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      classes={{root: names(styles.backdrop, defaultBackdrop && styles.default)}}
      hideBackdrop
    >
      <div
        className={names(styles.wrapper, wrapperClassName, defaultBackdrop && styles.defaultBackdrop)}
        onClick={() => onClickBackdrop && onClickBackdrop()}
      >
        <div className={styles.header} onClick={(e) => e.stopPropagation()}>
          {!defaultBackdrop ?
            <>
              <div className={styles.headerLogoBox}>
                <div className={styles.headerLogoBoxIcon}>
                  <img.LogoWhite />
                </div>
                <h3 className={styles.headerLogoBoxTitle}>{text.main.siteName}</h3>
              </div>
              <h2 className={styles.headerSubtitle}>
                {text.main.siteName} - {text.main.welcomeText}
              </h2>
            </>
          : null}
        </div>
        <form
          onClick={(e) => e.stopPropagation()}
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit && onSubmit()
          }}
          {...rest}
          className={`${styles.modal} ${className || ''}`}
        >
          {children}
        </form>
      </div>
    </Modal>
  )
}
