import {FC, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {CollectionsTypeEnum} from 'entities/collections/config'
import {UserType} from 'entities/profile'
import {ExtraDropdown} from 'features/extraDropdown'
import {Cross} from 'shared/ui/cross'
import {Dots} from 'shared/ui/icons'
import {MediaCollection} from 'widgets/mediaCollection'
import {setVideoPlayer, useVideoPlayerData} from './model'
import styles from './styles.module.scss'

type VideoPlayerProps = {
  rootStyles?: string
  iframeStyles?: string
  isCollections?: boolean
}

export const VideoPlayer: FC<VideoPlayerProps> = ({rootStyles, iframeStyles, isCollections}) => {
  const dispatch = useDispatch()
  const [ref, setRef] = useState<HTMLIFrameElement | null>(null)
  const {iframe, poster, collectionType, idLetter, idMessage, idUser, inCollection, idAttachment, isCollectionAdd} =
    useVideoPlayerData()
  const [openCollection, setOpenCollection] = useState<boolean>(false)
  const [isExtraOpen, setIsExtraOpen] = useState(false)

  const closeVideoPlayer = () => dispatch(setVideoPlayer({}))

  useEffect(() => {
    if (ref && !inCollection && !isCollectionAdd) {
      const handleCollection = (event: MessageEvent) => {
        if (event.source !== ref.contentWindow) return

        if (event.data && event.data.eventName === 'ended') {
          setOpenCollection(true)
        }
      }

      window.addEventListener('message', handleCollection)

      return () => {
        window.removeEventListener('message', handleCollection)
        setOpenCollection(false)
      }
    }
  }, [ref, inCollection, isCollectionAdd])

  useEffect(() => {
    const keydown = (event: KeyboardEvent) => {
      if (iframe && poster && event.key === 'Escape') {
        closeVideoPlayer()
      }
    }

    window.addEventListener('keydown', keydown)
    return () => window.removeEventListener('keydown', keydown)
  }, [iframe, poster])

  if (!iframe || !poster) return <></>

  return (
    <div className={`${styles.root} ${rootStyles || ''}`}>
      <iframe
        ref={setRef}
        className={`${styles.iframe} ${iframeStyles || ''}`}
        src={`${iframe}?poster=${poster}&autoplay=true`}
        allow='accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;'
        allowFullScreen
      />
      <Cross onClose={closeVideoPlayer} className={styles.close} />
      {isCollections ?
        <>
          <div className={styles.dotsIcon} onClick={() => setIsExtraOpen(true)}>
            <Dots />
          </div>
          <ExtraDropdown
            isOpen={isExtraOpen}
            onClose={() => setIsExtraOpen(false)}
            user={{id: idUser} as UserType}
            style={{right: 42, top: 50}}
            isInboxMenu
            isMessagesMenu
            isBlockMenu={false}
            isReportMenu={false}
            onCloseMedia={closeVideoPlayer}
          />
        </>
      : null}
      {(!isCollections && openCollection && !isCollectionAdd) || inCollection ?
        <MediaCollection
          typeContent={CollectionsTypeEnum.video}
          videoPoster={poster}
          type={collectionType}
          idLetter={idLetter}
          idMessage={idMessage}
          idUser={idUser}
          idAttachment={idAttachment}
          inCollection={inCollection}
        />
      : null}
    </div>
  )
}
