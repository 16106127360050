import {createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {useSelector} from 'react-redux'
import {VideoCollectionTypes} from 'entities/collections/config'

type VideoPlayerProps = {
  iframe?: string
  poster?: string
  isCollectionAdd?: boolean
  inCollection?: boolean
  collectionType?: VideoCollectionTypes
  idUser?: string
  idMessage?: string
  idLetter?: string
  idAttachment?: string
}

export const initialStateVideoPlayerModel: VideoPlayerProps = {}

export const videoPlayerModel = createSlice({
  name: 'videoPlayer',
  initialState: initialStateVideoPlayerModel,
  reducers: {
    setVideoPlayer: (_, {payload}: PayloadAction<VideoPlayerProps>) => payload
  }
})

export const {setVideoPlayer} = videoPlayerModel.actions

export const useVideoPlayerData = () => useSelector((state: RootState) => state.videoPlayer)

export const closeVideoPlayer = (dispatch: Dispatch) => dispatch(setVideoPlayer({}))
