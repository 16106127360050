export enum MIRRORS_KEYS {
  GOCHATTY = 'gochatty',
  LANADATE = 'lanadate'
}

export const Mirrors: Record<MIRRORS_KEYS, string[]> = {
  [MIRRORS_KEYS.GOCHATTY]: ['gochatty.asocial-team.com', 'gochatty.com'],
  [MIRRORS_KEYS.LANADATE]: ['lanadate.asocial-team.com', 'lanadate.com']
}

const LOCAL_MIRROR = Mirrors.gochatty[0]
const DEFAULT_MIRROR: MIRRORS_KEYS = MIRRORS_KEYS.GOCHATTY

const getKeyFromEnumByValue = (enumValue: string): string => {
  for (const key of Object.keys(Mirrors)) {
    if (Mirrors[key as keyof typeof Mirrors].includes(enumValue)) {
      return key
    }
  }
  return DEFAULT_MIRROR
}

export const currentMirror = getKeyFromEnumByValue(
  process.env.NODE_ENV === 'development' ? LOCAL_MIRROR : window.location.hostname
) as MIRRORS_KEYS

export const STAGE_URL = `https://${currentMirror}.asocial-team.com`

export const isMirror = (mirror: MIRRORS_KEYS) => currentMirror === mirror

// Пример проверок в модулях
// if (isMirror(MIRRORS_KEYS.GOCHATTY)) {
//   console.log('logic1')
// }

// if (isMirror(MIRRORS_KEYS.LANADATE)) {
//   console.log('logic2')
// }
