import {FC, useMemo} from 'react'
import {
  ContentAudioType,
  ContentDisappearingPhotoType,
  ContentDisappearingVideoType,
  ContentTextType,
  DeletedContentType,
  DialogItemType,
  messageTypes
} from 'entities/messages'
import {mediaVariants} from 'shared/lib'
import {Gift, InboxDot, Microphone, NoteSticky, Play} from 'shared/ui/icons'
import {RetryImage} from 'shared/ui/retryImage'
import styles from './styles.module.scss'

type ContentLastMessageProps = {
  dialog: DialogItemType
}

const isPurchasedContent = (content: ContentDisappearingPhotoType | ContentDisappearingVideoType) =>
  !(content.purchased === false && content.price && content.price > 0)

export const ContentLastMessage: FC<ContentLastMessageProps> = ({dialog}) => {
  const {lastMessage, interlocutor} = dialog
  const isMyMessage = lastMessage.to.id === interlocutor.id

  let content

  return useMemo(() => {
    if ((lastMessage.content as DeletedContentType)?.dateDeleted) {
      return <>Message removed</>
    }

    switch (lastMessage.type) {
      case messageTypes.text:
        return <div className={styles.text}>{(lastMessage.content as ContentTextType).text}</div>
      case messageTypes.letter:
        return (
          <>
            <InboxDot className={styles.letterIcon} />
            {isMyMessage ? 'Inbox message sent' : 'New inbox message'}
          </>
        )
      case messageTypes.photo:
      case messageTypes.disappearingPhoto:
        content = lastMessage.content as ContentDisappearingPhotoType

        return (
          <>
            {content.photo ?
              <RetryImage
                src={
                  content.photo.url +
                  (isPurchasedContent(content) ? mediaVariants.square32 : mediaVariants.square32blur)
                }
                alt='preview'
                className={styles.img}
              />
            : null}
            {content.text ?
              <div className={styles.text}>{content.text}</div>
            : `${isMyMessage ? 'You sent' : 'Sent you'} a photo`}
          </>
        )
      case messageTypes.video:
      case messageTypes.disappearingVideo:
        content = lastMessage.content as ContentDisappearingVideoType

        return (
          <>
            {content.video ?
              <div className={styles.video}>
                <RetryImage
                  src={content.video.jpgUrl}
                  alt='preview'
                  className={`${styles.img} ${isPurchasedContent(content) ? '' : styles.blur}`}
                />
                <Play className={styles.playIcon} />
              </div>
            : null}
            {content.text ?
              <div className={styles.text}>{content.text}</div>
            : `${isMyMessage ? 'You sent' : 'Sent you'} a video`}
          </>
        )
      case messageTypes.sticker:
        return (
          <>
            <div className={styles.stickerIcon}>
              <NoteSticky />
            </div>
            Sticker
          </>
        )
      case messageTypes.virtualGift:
        return (
          <>
            <div className={styles.giftIcon}>
              <Gift />
            </div>
            {isMyMessage ? 'You sent' : 'Sent you'} <span>a gift</span>
          </>
        )
      case messageTypes.audio:
        content = lastMessage.content as ContentAudioType

        return (
          <>
            <Microphone className={styles.micro} />
            {content.text ?
              <div className={styles.text}>{content.text}</div>
            : 'Voice message'}
          </>
        )
      default:
        return <></>
    }
  }, [lastMessage, interlocutor])
}
