import {CountryKeys} from 'shared/lib'
import {WalletType} from './payment'

export enum Gender {
  FEMALE = 'female',
  MALE = 'male'
}

export enum MaritalStatus {
  NOT_MARRIED = 'not_married',
  DIVORCED = 'divorced',
  WIDOWED = 'widowed'
}

export enum SurpriseCount {
  MAX_ITEM_COUNT = 7
}

export enum ChildrenCount {
  NO_CHILDREN = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE_OF_MORE = 5
}

export enum Education {
  HIGH_SCHOOL = 'high_school',
  HIGH_SCHOOL_NOT_COMPLETED = 'high_school_not_completed',
  COLLEGE = 'college',
  COLLEGE_NOT_COMPLETED = 'college_not_completed',
  UNIVERSITY = 'university',
  NOT_SPECIFIED = 'not_specified'
}

export enum UserStatus {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  DEACTIVATED = 'deactivated',
  DEPERSONALIZED = 'depersonalized'
}

export enum Drinking {
  TEETOTAL = 'teetotal',
  SOCIALLY = 'socially',
  OCCASIONALLY = 'occasionally',
  OFTEN = 'often'
}

export enum Smoking {
  NON_SMOKER = 'non-smoker',
  SOCIALLY = 'socially',
  SMOKER = 'smoker'
}

export enum SettingsKeys {
  RESTORE_ID = 'restoreId',
  IS_AUDIO_ENABLED = 'isAudioEnabled',
  IS_INTERESTS_ASKED = 'isInterestsAsked',
  IS_PHOTO_ASKED = 'isPhotoAsked',
  LAST_TIME_VERIFY_EMAIL_WINDOW = 'lastTimeVerifyEmailWindow',
  DISPLAY_COLLECTIONS_MODAL = 'displayCollectionsPresentModal',
  PUSH_MODAL_LAST_TIME_OPEN = 'pushModalLastTimeOpen',
  PUSH_MODAL_COUNT_OPEN = 'pushModalCountOpen',
  PUSH_MODAL_CANCEL = 'pushModalCancel',
  FRESH_CHAT_TAGS = 'freshChatTags' // Record<[channelId], new Date().toISOString()> channelId: number
}

export enum MARKETING_PARTNERS {
  GOOGLE = 'google',
  FACEBOOK = 'facebook'
}

export type ProfileType = {
  name: string
  gender: Gender
  email: string
  dateBirth: string
  country: CountryKeys
  city: string | null
  maritalStatus: MaritalStatus | null
  childrenCount: ChildrenCount | null
  occupation: string | null
  education: Education | null
  height: number | null
  weight: number | null
  drinking: boolean | null
  smoking: boolean | null
  timezone: string | null
  about: string | null
  avatarUrl: string | null
  videoAvatarUrl: string | null
  bannerUrl: string | null
  isConfirmed: boolean
  interests: InterestCategory[]
}

export type Me = {
  id: string
  collections: number
  profile: ProfileType
  status: UserStatus
  paymentsCount: number
  support: {
    enabled: boolean
    lt: string
    ts: string
  }
  settings: Partial<Record<SettingsKeys, any>> | null
  wallet: WalletType
  dateCreated: string
  ipCountry: CountryKeys | string
  partner: MARKETING_PARTNERS | null
}

export type Interest = {
  id: number
  name: string
}

export type InterestCategory = {
  name: string
  interests: Interest[]
}

export type GetInterestsResponse = {
  data?: InterestCategory[]
}

export type SearchUser = {
  id: string
  name: string
  age: number
  avatarUrl: string
  country?: CountryKeys | string
  city?: string
  photoCount: number
  videoCount: number
  isOnline: boolean
  isLiked: boolean
  isConfirmed: boolean
  isFavorite: boolean
  isPopular: boolean
  dateCreated: string
}

export type Video = {
  cfId: string
  url: string
  iframeUrl: string
  previewUrl: string
  gifUrl: string
  jpgUrl: string
}

export type IntroVideo = {
  purchased: boolean
  video: Video
}

const weightStart = 40
const weightEnd = 210
const weightStep = 1
const POUND = 2.20462

export const WeightList = Array.from({length: (weightEnd - weightStart) / weightStep + 1}, (_, i) => {
  const value = i + weightStart

  return {
    id: value,
    label: `${Math.floor(POUND * value)}lbs  (${value}kg)`
  }
})

const heightStart = 130
const heightEnd = 210
const heightStep = 1
const FOOT = 30.48
const INCH = 2.54

export const HeightList = Array.from({length: (heightEnd - heightStart) / heightStep + 1}, (_, i) => {
  const value = i + heightStart

  return {
    id: value,
    label: `${Math.floor(value / FOOT)}'${((value % FOOT) / INCH).toFixed(1)}" - ${value / 100}m`
  }
})

export enum NotificationStatus {
  GRANTED = 'granted',
  DEFAULT = 'default',
  DENIED = 'denied'
}
