import './index.scss'
import {Axios, setApi} from '@asocial/fe-utils'
import {lazy, useEffect, useState} from 'react'
import {currentMirror, STAGE_URL} from 'shared/mirrors/config'
import {useImgLoad} from 'shared/mirrors/img'
import {useTextLoad} from 'shared/mirrors/text'
import {withProviders} from './providers'
// inti styles
import(`shared/mirrors/${currentMirror}/styles/vars.scss`)
import(`shared/mirrors/${currentMirror}/styles/typography.scss`)

const Routing = lazy(() => import('../pages'))

const hideLoader = () => {
  const loader = document.querySelector('#globalLoader') as HTMLDivElement
  if (!loader) return

  loader.style.display = 'none'
}

function App() {
  const [isSetApi, setIsSetApi] = useState(false)
  const isLoadText = useTextLoad()
  const isLoadImage = useImgLoad()

  useEffect(() => {
    if (isSetApi) return

    if (process.env.NODE_ENV === 'development') {
      // @ts-ignore
      Axios.defaults.headers.common['CF-Access-Client-Id'] = process.env.REACT_APP_CF_ACCESS_CLIENT_ID
      // @ts-ignore
      Axios.defaults.headers.common['CF-Access-Client-Secret'] = process.env.REACT_APP_CF_ACCESS_CLIENT_SECRET
    }

    setApi(`${process.env.NODE_ENV === 'development' ? STAGE_URL : window.location.origin}/api`).finally(() =>
      setIsSetApi(true)
    )
  }, [])

  useEffect(() => {
    if (isSetApi && isLoadText && isLoadImage) {
      hideLoader()
    }
  }, [isSetApi, isLoadText, isLoadImage])

  if (!isSetApi || !isLoadText || !isLoadImage) return <></>

  return <Routing />
}

export default withProviders(App)
