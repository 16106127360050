import compose from 'compose-function'
import {withCropper} from './with-cropper'
import {withFP} from './with-fp'
import {withGoogle} from './with-google'
import {withLocalisation} from './with-localisation'
import {withQuery} from './with-query'
import {withSnackbar} from './with-snackbar'
import {withStore} from './with-store'

export const withProviders = compose(
  withFP,
  withStore,
  withQuery,
  withLocalisation,
  withCropper,
  withGoogle,
  withSnackbar
)
