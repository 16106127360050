import {Axios} from '@asocial/fe-utils'
import {createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {useSelector} from 'react-redux'
import {ImageType} from 'entities/messages'
import {CursorType, ErrorResponsesType, Video} from 'shared/types'
import {COLLECTIONS_LIMIT, CollectionsTypeEnum, CollectionsTabKeys} from './config'

export type CollectionType = {
  id: string
  idOwner: string
  type: CollectionsTypeEnum
  media: ImageType | Video
}

type CollectionsResponseType = AxiosResponse<{
  cursor: CursorType
  totalCount: number
  data: CollectionType[]
}>

export type CollectionsModelProps = {
  tab: CollectionsTabKeys
  cursor: CursorType
  collectionsList: CollectionType[]
  openModalCollections: boolean
  openNotificationCollection: boolean
  isCollectionLoading: boolean
  openCollectionGallery: boolean
  currentCollectionMedia: number
}

export const initialState: CollectionsModelProps = {
  tab: CollectionsTabKeys.ALL,
  cursor: '',
  collectionsList: [],
  openModalCollections: false,
  openNotificationCollection: false,
  isCollectionLoading: false,
  openCollectionGallery: false,
  currentCollectionMedia: 0
}

export const collectionsModel = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    setCollectionsTab: (state, {payload}: PayloadAction<CollectionsTabKeys>) => {
      state.cursor = ''
      state.collectionsList = []
      state.tab = payload
    },
    setOpenModalCollections: (state, {payload}: PayloadAction<boolean>) => {
      state.openModalCollections = payload
    },
    setOpenNotificationCollection: (state, {payload}: PayloadAction<boolean>) => {
      state.openNotificationCollection = payload
    },
    setCollectionsList: (state, {payload}: PayloadAction<{cursor: string; data: CollectionType[]}>) => {
      state.cursor = payload.cursor
      state.collectionsList.push(...payload.data)
    },
    setIsCollectionsLoading: (state, {payload}: PayloadAction<boolean>) => {
      state.isCollectionLoading = payload
    },
    setOpenCollectionGallery: (state, {payload}: PayloadAction<boolean>) => {
      state.openCollectionGallery = payload
    },
    setCurrentCollectionMedia: (state, {payload}: PayloadAction<number>) => {
      state.currentCollectionMedia = payload
    }
  }
})

export const {
  setCollectionsTab,
  setOpenModalCollections,
  setOpenNotificationCollection,
  setCollectionsList,
  setIsCollectionsLoading,
  setOpenCollectionGallery,
  setCurrentCollectionMedia
} = collectionsModel.actions

export const GetCollections = (dispatch: Dispatch) =>
  useQuery<
    CollectionsResponseType,
    ErrorResponsesType,
    CollectionsResponseType,
    [CursorType, CollectionsTabKeys, string]
  >({
    queryKey: [
      useSelector((state: RootState) => state.collections.cursor),
      useSelector((state: RootState) => state.collections.tab),
      'collection'
    ],
    queryFn: ({queryKey: [cursor, tab], signal}) =>
      Axios.get(`${tab === CollectionsTabKeys.ALL ? 'collection' : `collection/${tab}`}`, {
        signal,
        params: {
          limit: COLLECTIONS_LIMIT,
          cursor: cursor || null
        }
      })
        .then(({data}) => {
          dispatch(setCollectionsList(data))
          return data
        })
        .catch((error) => {
          throw error
        }),
    enabled: false,
    refetchOnWindowFocus: false
  })

export const useCollectionsTab = () => useSelector((state: RootState) => state.collections.tab)

export const useCollectionsCursor = () => useSelector((state: RootState) => state.collections.cursor)

export const useOpenModalCollections = () => useSelector((state: RootState) => state.collections.openModalCollections)

export const useCollectionsList = () => useSelector((state: RootState) => state.collections.collectionsList)

export const useIsCollectionLoading = () => useSelector((state: RootState) => state.collections.isCollectionLoading)

export const useOpenCollectionGallery = () => useSelector((state: RootState) => state.collections.openCollectionGallery)

export const useCurrentCollectionMedia = () =>
  useSelector((state: RootState) => state.collections.currentCollectionMedia)

export const useOpenNotificationCollection = () =>
  useSelector((state: RootState) => state.collections.openNotificationCollection)
