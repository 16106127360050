import MenuItem from '@mui/material/MenuItem'
import MuiSelect, {SelectProps as MuiSelectProps} from '@mui/material/Select'
import {FC, SyntheticEvent, useRef} from 'react'
import {ReactComponent as DropDownIcon} from 'shared/ui/icons/arrow-down-icon.svg'
import 'shared/ui/styles/popup.scss'
import 'shared/ui/styles/select.scss'

export type SelectProps = MuiSelectProps & {
  placeholder?: String
  sizePopup?: 'xs' | 'md' | 'xl'
  horizontalPopup?: 'left' | 'right'
  width?: string
  isShowPlaceholderItem?: boolean
}

export const Select: FC<SelectProps> = ({
  value,
  className,
  children,
  placeholder,
  sizePopup,
  horizontalPopup,
  style,
  width,
  isShowPlaceholderItem = true,
  onClose,
  onOpen,
  ...rest
}) => {
  const selectRef = useRef<HTMLElement>(null)

  const onOpenHandler = (event: SyntheticEvent) => {
    selectRef?.current?.classList.add('Mui-focused')
    onOpen && onOpen(event)
  }

  const onCloseHandler = (event: SyntheticEvent) => {
    selectRef?.current?.classList.remove('Mui-focused')
    onClose && onClose(event)
  }

  return (
    <MuiSelect
      {...rest}
      ref={selectRef}
      className={`override-select ${className}`}
      MenuProps={{
        autoFocus: false,
        classes: {
          paper: `override-popup-paper override-popup-paper-${sizePopup || 'md'}`,
          list: 'override-popup-list'
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: horizontalPopup || 'left'
        },
        transformOrigin: {
          vertical: -4,
          horizontal: horizontalPopup || 'left'
        }
      }}
      style={{width, ...style}}
      IconComponent={!rest.disabled ? DropDownIcon : () => null}
      onOpen={onOpenHandler}
      onClose={onCloseHandler}
      value={value ?? ''}
    >
      {placeholder ?
        <MenuItem
          sx={{display: isShowPlaceholderItem ? 'block' : 'none'}}
          value=''
          className='override-select-placeholder'
        >
          <div className='override-select-placeholder'>{placeholder}</div>
        </MenuItem>
      : null}
      {children}
    </MuiSelect>
  )
}
