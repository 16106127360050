import {useCallback, useState} from 'react'
import {useDispatch} from 'react-redux'
import {setOpenNotificationCollection} from 'entities/collections/model'
import {updateLetterAttachment} from 'entities/inbox/model/letters'
import {CollectionsMailRequest} from 'entities/inbox/model/threads'
import {CollectionMessageRequest, updateMessage} from 'entities/messages'
import {SAVE_COLLECTIONS_SOURCE} from 'entities/tracking/types'
import {upCollections} from 'entities/user'
import {trackSaveCollection} from 'features/tracking'

export const useMediaCollectionSave = (
  idUser?: string,
  idMessage?: string,
  idLetter?: string,
  idAttachment?: string,
  inCollection?: boolean,
  type?: string
) => {
  const dispatch = useDispatch()
  const [isSaved, setIsSaved] = useState(inCollection)
  const [isLoading, setIsLoading] = useState(false)

  const onSaveHandler = useCallback(() => {
    if (isSaved || isLoading) return

    const saveData = async () => {
      setIsLoading(true)
      try {
        if (idUser && idMessage) {
          const {data} = await CollectionMessageRequest(idMessage, idUser)
          dispatch(updateMessage(data))
          trackSaveCollection(type!, SAVE_COLLECTIONS_SOURCE.CHAT_MEDIA_GALLERY)
        } else if (idLetter && idUser && idAttachment) {
          const {data} = await CollectionsMailRequest(idAttachment, idLetter, idUser)
          dispatch(updateLetterAttachment(data))
          trackSaveCollection(type!, SAVE_COLLECTIONS_SOURCE.MAIL_MEDIA_GALLERY)
        } else {
          throw new Error('Invalid parameters for saving')
        }

        dispatch(upCollections())
        setIsSaved(true)
        dispatch(setOpenNotificationCollection(true))
      } catch (error) {
        console.error('Error saving collection:', error)
      } finally {
        setIsLoading(false)
      }
    }

    saveData().catch(console.error)
  }, [isSaved, isLoading, idUser, idMessage, idLetter, idAttachment, dispatch])

  return {onSaveHandler, isSaved, isLoading}
}
