export const getDiscount = (price: number, originPrice: number) => Math.ceil(100 - (price * 100) / originPrice)

export const getPerCoinValue = (price: number, credits: number) => (price / credits).toFixed(2)

export const PRICE = {
  CHAT: 2,
  CHAT_PHOTO_SEND: 10,
  CHAT_DISAPPEARING_PHOTO_SEND: 20,
  CHAT_DISAPPEARING_PHOTO_VIEW: 20,
  CHAT_VIDEO_SEND: 40,
  CHAT_VIDEO_VIEW: 40,
  CHAT_DISAPPEARING_VIDEO_SEND: 40,
  CHAT_DISAPPEARING_VIDEO_VIEW: 40,
  CHAT_AUDIO_SEND: 25,
  CHAT_AUDIO_VIEW: 25,
  COLLECTION_PHOTO: 30,
  COLLECTION_DISAPPEARING_PHOTO: 60,
  COLLECTION_VIDEO: 85,
  FLIRTCAST_MESSAGE: 5,
  PROFILE_INTRO_VIDEO: 50
}

//Mon, 26 Mar 2024 00:00:00 GMT
const BONUS_X_TIME = Date.UTC(2024, 2, 26)
const BONUS_TIME_CLOSED = Date.UTC(2024, 3, 9)

//delete everywhere after 2 weeks expired
export const isBonusLive = (userRegTime: string) => {
  const date = new Date()
  const dateCreated = new Date(userRegTime).getTime()

  const now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )

  // if user reg after X day - hide bonuses
  if (dateCreated >= BONUS_X_TIME) {
    return false
  }

  // if 2 weeks have passed since day X
  return now_utc < BONUS_TIME_CLOSED
}
