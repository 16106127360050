import {useMediaQuery} from '@mui/material'
import {FC, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {CollectionsTypeEnum} from 'entities/collections/config'
import {messageTypes} from 'entities/messages'
import {COLLECTION_PAYMENT_COUNT} from 'entities/profile'
import {useUser, useUserFreeCollections} from 'entities/user'
import {closeVideoPlayer} from 'features/videoPlayer'
import {names, PRICE} from 'shared/lib'
import {text} from 'shared/mirrors/text'
import {Collections, CollectionsActive} from 'shared/ui/icons'
import {Loader} from 'shared/ui/loader'
import {RetryImage} from 'shared/ui/retryImage'
import {useMediaCollectionSave} from './hooks/useMediaCollectionSave'
import PresentIcon from './img/present.webp'
import SaveIcon from './img/save.webp'
import styles from './styles.module.scss'

type MediaCollectionProps = {
  type?: string
  typeContent: CollectionsTypeEnum
  videoPoster?: string
  idLetter?: string
  idMessage?: string
  idUser?: string
  inCollection?: boolean
  idAttachment?: string
}

const mediaCollectionInfo = (isSmallScreen: boolean, freeUserCollections: number): Record<string, any> => ({
  save: {
    photo: {
      title: <p className={styles.title}>Save this media to collections</p>,
      text: {
        [messageTypes.disappearingPhoto]: (
          <p className={styles.text}>
            1 disappearing photo save{' '}
            {isSmallScreen ?
              <br />
            : null}
            <mark className={styles.mark}>
              {PRICE.COLLECTION_DISAPPEARING_PHOTO} {text.main.coin}
            </mark>{' '}
            <span>(lifelong)</span>
          </p>
        ),
        [messageTypes.photo]: (
          <p className={styles.text}>
            1 photo save{' '}
            <mark className={styles.mark}>
              {PRICE.COLLECTION_PHOTO} {text.main.coin}
            </mark>{' '}
            (lifelong)
          </p>
        )
      }
    },
    video: {
      title: <p className={styles.videoTitle}>Like this video? Save it to your collections</p>,
      text: {
        [messageTypes.disappearingVideo]: (
          <p className={styles.videoText}>
            1 disappearing video save{' '}
            {isSmallScreen ?
              <br />
            : null}
            <mark className={styles.mark}>
              {PRICE.COLLECTION_VIDEO} {text.main.coin}
            </mark>{' '}
            <span>(lifelong)</span>
          </p>
        ),
        [messageTypes.video]: (
          <p className={styles.videoText}>
            1 video save{' '}
            <mark className={styles.mark}>
              {PRICE.COLLECTION_VIDEO} {text.main.coin}
            </mark>{' '}
            <span>(lifelong)</span>
          </p>
        )
      }
    }
  },
  freeMedia: {
    title: <p className={styles.videoTitle}>Like this video? Save it to your collections</p>,
    text: (
      <p className={styles.text}>
        <mark className={styles.freeMark}>Save for free:</mark>{' '}
        <mark className={styles.freeMedia}>{freeUserCollections} media left</mark>
      </p>
    )
  }
})

const MediaInformation: FC<{title: string; text: string}> = ({title, text}) => (
  <div className={styles.information}>
    {title ?
      <div className={styles.title}>{title}</div>
    : null}
    {text ?
      <div className={styles.text}>{text}</div>
    : null}
  </div>
)

export const MediaCollection: FC<MediaCollectionProps> = ({
  type,
  typeContent,
  videoPoster,
  idLetter,
  idMessage,
  idUser,
  idAttachment,
  inCollection
}) => {
  const dispatch = useDispatch()
  const freeCollectionCount = useUserFreeCollections()
  const {paymentsCount} = useUser()
  const isMobile = useMediaQuery(`(max-width: ${styles.is665p})`)

  const info = useMemo(() => mediaCollectionInfo(isMobile, freeCollectionCount), [isMobile, freeCollectionCount])

  const {onSaveHandler, isSaved, isLoading} = useMediaCollectionSave(
    idUser,
    idMessage,
    idLetter,
    idAttachment,
    inCollection,
    type
  )

  const isVideo = useMemo(() => typeContent === CollectionsTypeEnum.video, [typeContent])

  const onClose = () => closeVideoPlayer(dispatch)

  const {title, text, icon} = useMemo(() => {
    if (freeCollectionCount) {
      return {
        title: isVideo ? info.freeMedia.title : '',
        text: info.freeMedia.text || '',
        icon: PresentIcon
      }
    }
    const saveMedia = info.save[typeContent]
    return saveMedia ?
        {title: saveMedia.title || '', text: type ? saveMedia.text[type] : '', icon: SaveIcon}
      : {title: '', text: '', icon: null}
  }, [typeContent, type, freeCollectionCount, isVideo])

  if (paymentsCount < COLLECTION_PAYMENT_COUNT) return null

  if (isSaved) {
    return (
      <div className={styles.inCollection}>
        Saved <CollectionsActive className={styles.savedInCollectionIcon} />
      </div>
    )
  }

  if (isVideo) {
    return (
      <div className={styles.wrapperVideo} onClick={onClose}>
        <div className={styles.collectionVideo} onClick={(event) => event.stopPropagation()}>
          <RetryImage src={videoPoster} alt='img' className={styles.img} />
          <MediaInformation title={title} text={text} />
          <div className={styles.button}>
            <div className={styles.notNow} onClick={onClose}>
              Not now
            </div>
            <div className={styles.save} onClick={onSaveHandler}>
              {isLoading ?
                <Loader />
              : <>
                  Save
                  <Collections className={styles.saveIcon} />
                </>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.collection}>
      {icon ?
        <RetryImage
          src={icon}
          alt='icon'
          className={names(styles.icon, Boolean(freeCollectionCount) && styles.freeIcon)}
        />
      : null}
      <MediaInformation title={title} text={text} />
      <div className={styles.click} onClick={onSaveHandler}>
        {isLoading ?
          <Loader />
        : <>
            Save
            <Collections className={styles.saveIcon} />
          </>
        }
      </div>
    </div>
  )
}
