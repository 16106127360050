import {Axios} from '@asocial/fe-utils'
import {AxiosResponse} from 'axios'
import {CursorType, ErrorResponsesType} from 'shared/types'
import {messageTypes} from './config'
import {ChatCountersType, DialogType, FlirtcastResponseType, MessageType} from './model'
import {GetChatRequestsResponse, SendMessageRequestType} from './types'

export const SendMessageRequest = (
  idUser: string,
  data: SendMessageRequestType,
  type: messageTypes
): Promise<AxiosResponse<MessageType, ErrorResponsesType>> => Axios.post(`chat/dialogs/${idUser}/${type}`, data)

export const CollectionMessageRequest = (idMessage: string, idUser: string): Promise<AxiosResponse<MessageType>> =>
  Axios.post(`/chat/dialogs/${idUser}/messages/${idMessage}/collect`)

export const PurchaseMessageRequest = (idUser: string, idMessage: string): Promise<AxiosResponse<MessageType>> =>
  Axios.post(`/chat/dialogs/${idUser}/messages/${idMessage}/purchase`)

export const GetChatRequestsRequest = (cursor?: CursorType): Promise<AxiosResponse<GetChatRequestsResponse>> =>
  Axios.get(`/chat/dialogs/${DialogType.REQUEST}`, {params: {cursor: cursor || null}})

export const ReadMessage = (idUser: string, idMessage: string): Promise<AxiosResponse<{unreadCount: number}>> =>
  Axios.patch(`/chat/dialogs/${idUser}/read`, {idLastMessage: idMessage})

export const GetChatCounters = (): Promise<AxiosResponse<ChatCountersType>> => Axios.get('/chat/counters')

export const ChatTyping = (idUser: string): Promise<null> => Axios.post(`/chat/dialogs/${idUser}/typing`)

export const FlirtcastMessage = (text: string): Promise<AxiosResponse<FlirtcastResponseType, ErrorResponsesType>> =>
  Axios.post('/chat/flirtcast', {text})
