import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {useSelector} from 'react-redux'
import {PhotoCollectionTypes} from 'entities/collections/config'
import {PhotoType, PhotoTypes} from 'entities/messages'
import {CursorType} from 'shared/types'

export type CurrentImageType = {
  type: PhotoTypes
  src: string
  index: number
  label?: string
  id?: string
  isCollectionAdd?: boolean
  collectionType?: PhotoCollectionTypes
  inCollection?: boolean
  idUser?: string
  idMessage?: string
  idLetter?: string
  idAttachment?: string
}

export enum GalleryTypes {
  myProfile = 'myProfile',
  profile = 'profile',
  chat = 'chat'
}

type GalleryProps = {
  galleryPhotos: PhotoType[]
  isOpen: boolean
  isPhotoList: boolean
  isDelete: boolean
  currentImage: CurrentImageType
  galleryType: GalleryTypes
}

export const initialStateGalleryModel: GalleryProps = {
  galleryPhotos: [],
  isOpen: false,
  isPhotoList: true,
  isDelete: false,
  currentImage: {type: PhotoTypes.public, src: '', index: 0, id: ''},
  galleryType: GalleryTypes.myProfile
}

export const galleryModel = createSlice({
  name: 'gallery',
  initialState: initialStateGalleryModel,
  reducers: {
    openGallery: (state, {payload}: PayloadAction<{isDelete: boolean} | undefined>) => {
      state.isOpen = true
      state.isDelete = payload?.isDelete || false
    },
    closeGallery: (state) => {
      state.isOpen = false
      state.isPhotoList = true
    },
    // TODO: выпелить cursor
    setGalleryPhotos: (state, {payload}: PayloadAction<{photos: PhotoType[]; cursor?: CursorType}>) => {
      state.galleryPhotos = payload.photos
    },
    deleteGalleryPhotos: (state, {payload}: PayloadAction<string[]>) => {
      state.galleryPhotos = state.galleryPhotos.filter((photo) => !payload.includes(photo.id))
    },
    setCurrentImage: (state, {payload}: PayloadAction<CurrentImageType>) => {
      state.currentImage = {...payload}
    },
    clearStateGallery: () => initialStateGalleryModel,
    setGalleryType: (state, {payload}: PayloadAction<GalleryTypes>) => {
      state.galleryType = payload
    },
    setGalleryIsPhotoList: (state, {payload}: PayloadAction<boolean>) => {
      state.isPhotoList = payload
    }
  }
})

export const {
  setGalleryPhotos,
  deleteGalleryPhotos,
  closeGallery,
  openGallery,
  setCurrentImage,
  clearStateGallery,
  setGalleryType,
  setGalleryIsPhotoList
} = galleryModel.actions

export const useGallery = () => useSelector((state: RootState) => state.gallery)

export const useOpenStateGallery = () => useSelector((state: RootState) => state.gallery.isOpen)

export const useGalleryIsPhotoList = () => useSelector((state: RootState) => state.gallery.isPhotoList)
