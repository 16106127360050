import {FunctionComponent, SVGProps, useEffect, useState} from 'react'
import {currentMirror} from './config'

type Svg = FunctionComponent<SVGProps<SVGSVGElement> & {title?: string}>

type imgType = {
  Logo: Svg
  LogoWhite: Svg
  Name: Svg
  Coin: Svg
  CoinWhite: Svg
  CreditsIcon: Svg
  TriangleExclamation: Svg
  SurpriseBox: Svg
  HeartBroken: Svg
  GirlDisabled: Svg
  GirlEnabled: Svg
  ManDisabled: Svg
  ManEnabled: Svg
  EmptyInbox: string
  Flirtcast: string
  FlirtcastFailed: string
  Confirmed: string
  RealGift: string
  DefaultBanner: string
  FrozenCardWarning: string
  OopsImage: string
  ResendCompleteConfirmed: string
  NewVersionSetting: string
  NewVersionMegaphone: string
  Deactivation: string
  StayedNotification: string
  Browser: string
  CollectionsEmptyIcon: string
  CollectionsFaqModalIcon: string
  CollectionsPresentModalBenefits: string
  CollectionsPresentModalCollections: string
  CollectionsPresentModalOffer: string
}

export const img: imgType = {
  Logo: () => <></>,
  LogoWhite: () => <></>,
  Name: () => <></>,
  Coin: () => <></>,
  CoinWhite: () => <></>,
  CreditsIcon: () => <></>,
  TriangleExclamation: () => <></>,
  SurpriseBox: () => <></>,
  HeartBroken: () => <></>,
  GirlDisabled: () => <></>,
  GirlEnabled: () => <></>,
  ManDisabled: () => <></>,
  ManEnabled: () => <></>,
  EmptyInbox: '',
  Flirtcast: '',
  FlirtcastFailed: '',
  Confirmed: '',
  RealGift: '',
  DefaultBanner: '',
  FrozenCardWarning: '',
  OopsImage: '',
  ResendCompleteConfirmed: '',
  NewVersionSetting: '',
  NewVersionMegaphone: '',
  Deactivation: '',
  StayedNotification: '',
  Browser: '',
  CollectionsEmptyIcon: '',
  CollectionsFaqModalIcon: '',
  CollectionsPresentModalCollections: '',
  CollectionsPresentModalOffer: '',
  CollectionsPresentModalBenefits: ''
}

export function useImgLoad(): boolean {
  const [isLoad, setIsLoad] = useState(false)

  useEffect(() => {
    const loading = async () => {
      const icons = await import(`shared/mirrors/${currentMirror}/img/icons.ts`)

      for (const imgName of Object.keys(img)) {
        // @ts-ignore
        img[imgName] = icons[imgName]
      }

      setIsLoad(true)
    }

    loading().catch(console.error)
  }, [])
  return isLoad
}
