import Backdrop from '@mui/material/Backdrop'
import {useQueryClient} from '@tanstack/react-query'
import {CSSProperties, FC, useEffect, useState, MouseEvent, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {APP_MODALS_KEY, useModalEffect} from 'entities/app/helper'
import {PinUnpinThread, useOpenThread} from 'entities/inbox/model/threads'
import {
  ChatActivitySetAbuse,
  chatActivitySetAbuseQueryKey,
  ChatActivitySetBlock,
  ChatUserType,
  PinUnpinDialog,
  useCanOrderRealGift,
  useChatPairConnection,
  useOpenDialog
} from 'entities/messages'
import {UserType} from 'entities/profile'
import {mediaVariants} from 'shared/lib'
import {Avatar} from 'shared/ui/avatar'
import {Button} from 'shared/ui/buttons'
import {Form} from 'shared/ui/form'
import {Cross, CircleExclamation, Hand, PinActive, Pin, Delivery, Inbox, MyMessages} from 'shared/ui/icons'
import {Modal} from 'shared/ui/modal'
import {createSnackbar} from 'shared/ui/snackbar'
import {TextField} from 'shared/ui/textField'
import styles from './styles.module.scss'

type ExtraDropdownProps = {
  isOpen: boolean
  onClose: () => void
  user: ChatUserType | UserType
  style?: CSSProperties
  isPinMenu?: boolean
  isChat?: boolean
  isMessagesMenu?: boolean
  isInboxMenu?: boolean
  isBlockMenu?: boolean
  isReportMenu?: boolean
  onCloseMedia?: () => void
  isLeft?: boolean
}

export const ExtraDropdown: FC<ExtraDropdownProps> = ({
  isOpen,
  isPinMenu,
  isChat,
  onClose,
  user,
  style,
  isMessagesMenu,
  isInboxMenu,
  isBlockMenu = true,
  isReportMenu = true,
  onCloseMedia,
  isLeft
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isReportAbuseModal, setIsReportAbuseModal] = useState(false)
  const {isBlock} = useChatPairConnection()
  const {refetch} = ChatActivitySetBlock(user.id, !isBlock, dispatch)
  const dialog = useOpenDialog()
  const thread = useOpenThread()
  const canOrderRealGift = useCanOrderRealGift()
  const {refetch: pinUnpinDialog} = PinUnpinDialog(user.id, !dialog?.isPinned ?? false, dispatch)
  const {refetch: pinUnpinThread} = PinUnpinThread(user.id, !thread?.pinned ?? false, dispatch)
  const isPinShow = useMemo(() => isPinMenu && ((isChat && dialog) || (!isChat && thread)), [isPinMenu, dialog, thread])
  const isPinned = useMemo(
    () => (isChat ? dialog?.isPinned : thread?.pinned),
    [isChat, dialog?.isPinned, thread?.pinned]
  )

  const onCloseHandler = (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    event.stopPropagation()
    onClose()
  }

  const onDeliveryHandler = (event: MouseEvent<HTMLDivElement, Event>) => {
    event.stopPropagation()
    onCloseMedia && onCloseMedia()
    navigate(`/order/${user.id}`)
  }

  const onBlockHandler = (event: MouseEvent<HTMLDivElement, Event>) => {
    event.stopPropagation()

    refetch().catch(console.error)
  }

  const onPinHandler = (event: MouseEvent<HTMLDivElement, Event>) => {
    event.stopPropagation()

    isChat ? pinUnpinDialog().catch(console.error) : pinUnpinThread().catch(console.error)
  }

  const onReportAbuseHandler = (event: MouseEvent<HTMLDivElement, Event>) => {
    event.stopPropagation()

    setIsReportAbuseModal(true)
  }

  const onMessagesHandler = (event: MouseEvent<HTMLDivElement, Event>) => {
    event.stopPropagation()
    onCloseMedia && onCloseMedia()
    navigate(`/messages/${user.id}`)
  }

  const onInboxHandler = (event: MouseEvent<HTMLDivElement, Event>) => {
    event.stopPropagation()
    onCloseMedia && onCloseMedia()
    navigate(`/inbox/${user.id}`)
  }

  const onCloseReportAbuseModal = () => {
    setIsReportAbuseModal(false)
    onClose()
  }

  return (
    <>
      <Backdrop invisible open={isOpen} onClick={onCloseHandler} className={styles.backdrop} />

      <div className={`${styles.dropdown} ${!isOpen || isReportAbuseModal ? styles.hidden : ''}`} style={style}>
        {isMessagesMenu ?
          <div className={`${styles.menuItem} ${styles.gray} ${isLeft && styles.left}`} onClick={onMessagesHandler}>
            Messages <MyMessages />
          </div>
        : null}

        {isInboxMenu ?
          <div className={`${styles.menuItem} ${styles.gray} ${isLeft && styles.left}`} onClick={onInboxHandler}>
            Inbox <Inbox />
          </div>
        : null}

        {canOrderRealGift ?
          <div className={`${styles.menuItem} ${styles.gray} ${isLeft && styles.left}`} onClick={onDeliveryHandler}>
            Delivery <Delivery />
          </div>
        : null}

        {isPinShow ?
          <div className={`${styles.menuItem} ${styles.gray} ${isLeft && styles.left}`} onClick={onPinHandler}>
            {isPinned ?
              <span>Unpin</span>
            : <span>Pin</span>}
            {isPinned ?
              <PinActive />
            : <Pin />}
          </div>
        : null}

        {isBlockMenu ?
          <div className={styles.menuItem} onClick={onBlockHandler}>
            <span>
              {isBlock ?
                <span>Unblock</span>
              : <span>Block</span>}{' '}
              <span>user</span>
            </span>
            <Hand />
          </div>
        : null}

        {isReportMenu ?
          <div className={styles.menuItem} onClick={onReportAbuseHandler}>
            Report abuse
            <CircleExclamation />
          </div>
        : null}
      </div>

      {user && isOpen ?
        <ReportAbuseModal isOpen={isReportAbuseModal} onClose={onCloseReportAbuseModal} user={user} idUser={user.id} />
      : null}
    </>
  )
}

type ReportAbuseModalProps = {
  isOpen: boolean
  onClose: () => void
  user?: ChatUserType | UserType
  idUser: string
}

export const ReportAbuseModal: FC<ReportAbuseModalProps> = ({isOpen, onClose, user, idUser}) => {
  const [message, setMessage] = useState('')
  const {refetch, isFetching, isSuccess} = ChatActivitySetAbuse(idUser, message)
  const queryClient = useQueryClient()
  const isVisible = useModalEffect(isOpen, APP_MODALS_KEY.EXTRA_DROPDOWN)

  useEffect(() => {
    if (!isSuccess) return

    onClose()
    setMessage('')
    createSnackbar({text: 'Abuse report successfully'})
    queryClient.removeQueries({queryKey: chatActivitySetAbuseQueryKey})
  }, [isSuccess, onClose])

  const submitHandler = () => refetch().catch(console.error)

  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      open={isVisible}
      onClose={onClose}
      onClick={(event) => event.stopPropagation()}
    >
      <Form
        className={`${styles.form} ${styles.customScroll}`}
        onSubmit={(event) => {
          event.stopPropagation()
          submitHandler().catch(console.error)
        }}
      >
        <div className={styles.header}>
          <h5 className={styles.title}>Report</h5>
          <div onClick={onClose} className={styles.closeButton}>
            <Cross />
          </div>
        </div>

        <div className={styles.wrapper}>
          <div className={styles.userWrapper}>
            <Avatar src={user?.avatarUrl + mediaVariants.square64} alt={user?.name} isOnline={user?.isOnline} />

            <div className={styles.summary}>
              <div className={styles.name}>{user?.name}</div>
              <div className={styles.text}>You are going to report this user</div>
            </div>
          </div>

          <TextField
            label='Describe abuse'
            placeholder='Describe detailed reason of abuse'
            multiline
            inputContainerStyles={{height: 194}}
            withCounter
            required
            value={message}
            maxLength={1000}
            onChange={(event) => setMessage(event.target.value)}
          />

          <div className={styles.report}>
            <Button isLoading={isFetching} type='submit' styleType='primary'>
              Report abuse
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  )
}
