import heic2any from 'heic2any'

export const APPLE_IMAGE_REGEX = /\.(heic|heif)$/

export const fileToBlob = async (file: File) => new Blob([new Uint8Array(await file.arrayBuffer())], {type: file.type})

export const createFileFormData = async (file: File) => {
  const formData = new FormData()
  const blob = await fileToBlob(file)
  formData.append('file', blob)
  return formData
}

export const isHeic = (file: File) => APPLE_IMAGE_REGEX.test(file.name)

export const isImage = (file: File) => file.type.includes('image/') || isHeic(file)

export const checkHeic = async (file: File): Promise<File> => {
  if (isHeic(file)) {
    file = (await heic2any({blob: file, toType: 'image/png'})) as File
  }

  return file
}
