import {NeutralNotificationTypes, NotificationTypes} from 'entities/activity'

declare global {
  interface Window {
    fbq: {
      init: (pixelId: string) => void
      getState: () => {
        pixelInitializationTime: number
        pixels: []
      }
    }
    dataLayer: Record<string, any>[]
  }
}

export enum TRACKING_EVENTS {
  SEARCH = 'search',
  SEARCH_VIEW = 'searchView',
  BELL_CLICK = 'bellClick',
  OPEN_PAYMENT_MODAL = 'openPaymentModal',
  PHOTO_VIEW = 'photoView',
  CHAT_NOW = 'chatNowClick',
  VIRTUAL_GIFT = 'virtualGift',
  NEW_LIMIT = 'newLimit',
  CHAT_REQUESTS_COUNT = 'chatRequestsCount',
  REAL_GIFT_PAGE = 'realGift',
  SAVE_COLLECTIONS = 'saveCollections',
  TOPICS_PAGE_OPEN = 'TopicsPageOpen',
  TOPICS_PAGE_CLOSE = 'TopicsPageClose'
}

export enum CHAT_NOW_SOURCE {
  PROFILE = 'profile',
  PRIVATE_MEDIA = 'private',
  SEARCH = 'search',
  FAVORITE = 'favorite',
  CONTACTS = 'contacts',
  SIMILAR_PROFILES = 'similarProfiles'
}

export enum SAVE_COLLECTIONS_SOURCE {
  CHAT = 'chat',
  CHAT_MEDIA_GALLERY = 'chatMediaGallery',
  MAIL_MEDIA_GALLERY = 'mailMediaGallery'
}

export type TrackingEventType = {
  event: TRACKING_EVENTS | NotificationTypes | NeutralNotificationTypes
  context?: null | object
  date?: number
}

export type FacebookPixelType = 'lead' | 'purchase'

export type PixelFacebookWSType = {
  type: FacebookPixelType
  fbq: string
  params: {
    value: number
    currency: 'USD'
  } | null
}
