import {FormEventHandler, FormHTMLAttributes, forwardRef, ForwardRefRenderFunction, ReactNode, Ref} from 'react'

interface IFormProps extends FormHTMLAttributes<HTMLFormElement> {
  children: ReactNode
  onSubmit?: FormEventHandler
  ref?: Ref<HTMLFormElement>
}

const CustomForm: ForwardRefRenderFunction<HTMLFormElement, IFormProps> = (props, ref) => {
  const {...otherProps} = props

  return (
    <form
      {...otherProps}
      ref={ref}
      onSubmit={(e) => {
        e.preventDefault()
        props.onSubmit && props.onSubmit(e)
      }}
    >
      {props.children}
    </form>
  )
}

export const Form = forwardRef(CustomForm)
