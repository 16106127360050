import {Dispatch} from '@reduxjs/toolkit'
import moment from 'moment/moment'
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {CountryKeys} from 'shared/lib'
import {TEXT_ERRORS} from 'shared/lib/constants'
import {isMirror, MIRRORS_KEYS} from 'shared/mirrors/config'
import {text} from 'shared/mirrors/text'
import {createSnackbar} from 'shared/ui/snackbar'
import {VerifyEmail} from '../user'
import {
  addAppModal,
  removeAppModal,
  setSuccessSentEmailModalOpen,
  setVerifyEmailLastTimeClick,
  useAppModalsFirstVisible
} from './model'

export const onClickVerifyEmail = (timerStart: () => void, dispatch: Dispatch, isOpenSuccessModal: boolean = true) =>
  VerifyEmail()
    .then(() => {
      dispatch(setVerifyEmailLastTimeClick(moment().format()))
      isOpenSuccessModal && dispatch(setSuccessSentEmailModalOpen(true))
    })
    .catch((error) => {
      console.error(error)
      if (error.status === 429) createSnackbar({text: TEXT_ERRORS.TOO_MANY_REQUESTS})
    })
    .finally(timerStart)

export const useModalEffect = (isOpen: boolean, keyModal: APP_MODALS_KEY) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(isOpen ? addAppModal(keyModal) : removeAppModal(keyModal))
  }, [isOpen])

  return useAppModalsFirstVisible(keyModal)
}

export enum APP_MODALS_KEY {
  DATING_SAFETY_POLICY = 'datingSafetyPolicy',
  EXTRA_DROPDOWN = 'extraDropdown',
  FLIRTCAST_INFO = 'flirtcastInfo',
  LEARN_MORE = 'learnMore',
  MATCH = 'match',
  SWITCH_BROWSER = 'switchBrowser',
  FAQ_INBOX = 'faqInbox',
  TOPIC = 'topic',
  CHANGED_EMAIL = 'changedEmail',
  INTERESTS = 'interests',
  SUCCESS_SENT_EMAIL = 'successSentEmail'
}

export const getAddressCompany = (
  detectedCountry?: CountryKeys,
  initialCountry?: CountryKeys,
  withPrefix?: boolean
) => {
  const isUSCountry = detectedCountry === 'US' || initialCountry === 'US'

  if (withPrefix) {
    if (isMirror(MIRRORS_KEYS.GOCHATTY)) {
      return isUSCountry ?
          <>
            US: <span>{text.main.usAddress}</span>
          </>
        : <>
            Estonia: <span>{text.main.estoniaAddress}</span>, RN 16772555
          </>
    }

    if (isMirror(MIRRORS_KEYS.LANADATE)) {
      return <span>London: {text.main.mainAddress}</span>
    }
  }

  if (isMirror(MIRRORS_KEYS.GOCHATTY)) {
    return isUSCountry ? <span>{text.main.usAddress}</span> : <span>{text.main.mainAddress}</span>
  }

  if (isMirror(MIRRORS_KEYS.LANADATE)) {
    return <span>{text.main.mainAddress}</span>
  }
}
