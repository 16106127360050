import {CSSProperties, FC, MouseEventHandler, ReactElement} from 'react'
import {img} from 'shared/mirrors/img'
import {Gender} from 'shared/types'
import styles from './styles.module.scss'

type SexIconProps = {
  onClick: MouseEventHandler<HTMLDivElement>
  style?: CSSProperties
  isActive: boolean
  gender: Gender
}

export const SexIcon: FC<SexIconProps> = ({onClick, isActive, gender}) => {
  const icon: ReactElement =
    gender === Gender.MALE ?
      isActive ? <img.ManEnabled />
      : <img.ManDisabled />
    : isActive ? <img.GirlEnabled />
    : <img.GirlDisabled />

  return (
    <div onClick={onClick} className={`${styles.sexIcon} ${isActive ? styles[`active__${gender}`] : ''}`}>
      <div className={styles.icon}>{icon}</div>
    </div>
  )
}
