import {AttachmentTypeEnum} from 'entities/inbox/types'
import {messageTypes} from 'entities/messages'
import {PRICE} from 'shared/lib'
import {MediaContent, PhotoPrice, Stars} from 'shared/ui/icons'

export const COLLECTIONS_LIMIT = 30

export enum CollectionsTypeEnum {
  photo = 'photo',
  video = 'video'
}

export enum CollectionsTabKeys {
  ALL = 'all',
  PHOTO = 'photo',
  VIDEO = 'video'
}

export type CollectionsTabItem = {
  id: CollectionsTabKeys
  label: string
}

export type PhotoCollectionTypes = messageTypes.photo | messageTypes.disappearingPhoto | AttachmentTypeEnum.PHOTO
export type VideoCollectionTypes = messageTypes.video | messageTypes.disappearingVideo | AttachmentTypeEnum.VIDEO

export const collectionsTabList: CollectionsTabItem[] = [
  {
    id: CollectionsTabKeys.ALL,
    label: 'All media'
  },
  {
    id: CollectionsTabKeys.PHOTO,
    label: 'Photo'
  },
  {
    id: CollectionsTabKeys.VIDEO,
    label: 'Video'
  }
]

export const collectionsPricesArray = [
  {id: 1, title: 'Photo', coins: `x${PRICE.COLLECTION_PHOTO}`, Icon: PhotoPrice},
  {id: 2, title: 'Disappearing photo', coins: `x${PRICE.COLLECTION_DISAPPEARING_PHOTO}`, Icon: Stars},
  {id: 3, title: 'Regular or Disappearing video', coins: `x${PRICE.COLLECTION_VIDEO}`, Icon: MediaContent}
]
