import MuiModal, {ModalProps as MuiModalProps} from '@mui/material/Modal'
import {forwardRef, ForwardRefRenderFunction, useRef} from 'react'
import './styles.scss'

export const Box: ForwardRefRenderFunction<HTMLDivElement, MuiModalProps> = (
  {children, classes, ...rest},
  forwardRef
) => {
  const ref = useRef<HTMLDivElement | null>(null)

  const setRef = (node: HTMLDivElement | null) => {
    ref.current = node

    if (typeof forwardRef === 'function') {
      forwardRef(node)
    } else if (forwardRef) {
      forwardRef.current = node
    }
  }

  return (
    <MuiModal ref={setRef} classes={{root: 'override-menu-root', backdrop: 'override-backdrop', ...classes}} {...rest}>
      {children}
    </MuiModal>
  )
}

export const Modal = forwardRef(Box)
