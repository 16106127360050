import CircularProgress, {CircularProgressProps} from '@mui/material/CircularProgress'
import './styles.scss'
import {FC} from 'react'
import {names} from 'shared/lib'
import styles from './styles.module.scss'

type OverrideCircularProgress = {
  innerColor?: 'white'
  colorBack?: 'white'
}

export const Loader: FC<CircularProgressProps & OverrideCircularProgress> = ({
  className,
  innerColor,
  size = 20,
  colorBack
}) => (
  <span className={`override-circularProgress ${className || ''}`}>
    <CircularProgress
      classes={{root: 'override-circularProgressActive-root'}}
      size={size}
      style={{color: innerColor === 'white' ? styles.white : ''}}
    />
    <CircularProgress
      classes={{
        root: names(
          'override-circularProgressInactive-root',
          innerColor === 'white' && 'white',
          colorBack === 'white' && 'whiteBack'
        )
      }}
      variant='determinate'
      size={size}
      thickness={3}
      value={100}
    />
  </span>
)
