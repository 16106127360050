import moment from 'moment'
import {useEffect} from 'react'
import {searchPeopleFiltersType} from 'entities/searchPeople'
import {SendTrackedEvents} from 'entities/tracking/api'
import {events, trackEvent} from 'entities/tracking/lib'
import {CHAT_NOW_SOURCE, SAVE_COLLECTIONS_SOURCE, TRACKING_EVENTS} from 'entities/tracking/types'
import {ProfileType} from 'shared/types'

const MAX_EVENTS = 1000
const INTERVAL_SENDING = 10000

export const sendEvents = () => {
  if (events.length === 0) return

  const obj = [...events]
  events.length = 0

  SendTrackedEvents(obj)
    .catch((e) => {
      events.push(...obj)
      console.error(e)
    })
    .finally(() => {
      //error with request, clear arr for reduce memory size
      if (events.length > MAX_EVENTS) events.length = 0
    })
}

export const Tracking = () => {
  useEffect(() => {
    const interval = setInterval(() => sendEvents(), INTERVAL_SENDING)

    return () => clearInterval(interval)
  }, [])

  return <></>
}

export const trackSearchEvent = ({filters, profile}: {filters: searchPeopleFiltersType; profile: ProfileType}) => {
  trackEvent({
    event: TRACKING_EVENTS.SEARCH,
    context: {
      filters: {...filters},
      user: {
        age: moment().diff(profile.dateBirth, 'years'),
        gender: profile.gender,
        country: profile.country
      }
    }
  })
}

export const trackChatNowEvent = (source: CHAT_NOW_SOURCE) => {
  trackEvent({
    event: TRACKING_EVENTS.CHAT_NOW,
    context: {source}
  })
}

export const trackVirtualGiftEvent = (giftId: number, success: boolean) => {
  trackEvent({
    event: TRACKING_EVENTS.VIRTUAL_GIFT,
    context: {
      giftId,
      success,
      path: location.pathname
    }
  })
}

export const trackRealGiftPage = (source: string) => {
  trackEvent({event: TRACKING_EVENTS.REAL_GIFT_PAGE, context: {source}})
}

export const trackSaveCollection = (type: string, place: SAVE_COLLECTIONS_SOURCE) => {
  if (type && place) {
    trackEvent({event: TRACKING_EVENTS.SAVE_COLLECTIONS, context: {type, place}})
  }
}
